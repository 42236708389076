import { useEffect, useState } from "react";
import { Card, Container } from "reactbulma";
import "./App.css";
import io from "socket.io-client";
import LoginScreen from "./components/LoginScreen/LoginScreen";
import Particle from "./components/Particle/Particle";
import Panel from "./components/Panel/Panel";
function App() {
  let socket = io("http://80.253.244.11:50056");
  const [data, setData] = useState("");
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    socket.on("loginResponse", (auth) => {
      setAuth(auth);
    });
    socket.on("data", (data) => {
      setData(data);
    });
    socket.on("qr", (qr) => {
      setData(data);
    });
  }, []);
  const connect = (ip) => {
    socket.close();
    socket = io(ip);
    socket.on("loginResponse", (auth) => {
      setAuth(auth);
    });
    socket.on("data", (data) => {
      setData(data);
    });
    socket.on("qr", (qr) => {
      setData(data);
    });
  };
  const handleLogin = (sifre) => {
    socket.emit("login", { password: sifre });
  };
  connect();
  return (
    <div className="App">
      <Particle />
      <Container>
        <Card.Content>
          {auth ? (
            <Panel data={data} socket={socket} />
          ) : (
            <LoginScreen handleLogin={handleLogin} connect={connect} />
          )}
        </Card.Content>
      </Container>
    </div>
  );
}

export default App;
