import React from "react";
import "./Panel.scss";
import LoadingSvg from "../../assets/loading.svg";
import ResetSvg from "../../assets/mobile-phone.svg";
import { Button, Title, SubTitle } from "reactbulma";
import QRCode from "qrcode.react";
function NewlineText(props) {
  const text = props.text;
  const newText = text.split("\n").map((str) => (
    <span className="mesajyazi" key={Math.random(11)}>
      <br />
      {str}
    </span>
  ));

  return newText;
}

const Panel = ({ data, socket }) => {
  return (
    <div className="panel">
      <div className="toolbar">
        <Button light onClick={() => socket.emit("data")}>
          <img src={LoadingSvg} className="buttonImg" /> Güncelle
        </Button>
        <Button danger onClick={() => socket.emit("resetQr")}>
          <img src={ResetSvg} className="buttonImg" />
          Yetkiyi Sıfırla
        </Button>
      </div>
      {data ? (
        data.auth ? (
          <div>
            <div className="row">
              <div>
                <SubTitle className="subtitle">Yetki Durumu</SubTitle>
                <Title className="title">
                  {data.auth ? "Yetkili" : "Yetkisiz"}
                </Title>
              </div>
              <div>
                <SubTitle>Bağlanılan Bot</SubTitle>
                <Title>{data.bot}</Title>
              </div>
            </div>
            <div className="row">
              <div style={{ position: "relative" }}>
                <SubTitle className="subtitle">
                  Bağlı Telefon Bilgileri
                </SubTitle>
                <SubTitle className="subtitle">
                  <img
                    src={data.user.imgUrl}
                    style={{
                      position: "absolute",
                      left: "74%",
                      bottom: "10px",
                      width: "25%",
                      borderRadius: "25%",
                    }}
                    alt=""
                  />
                  Numara : <b>{data.user.jid.split("@")[0]}</b> <br />
                  Whatsapp Adı : <b>{data.user.name}</b>
                  <br />
                  Marka/Model :{" "}
                  <b>
                    {data.user.phone.device_manufacturer +
                      " / " +
                      data.user.phone.device_model}
                  </b>
                  <br />
                </SubTitle>
              </div>
              <div>
                <SubTitle>Aktif Süre</SubTitle>
                <Title>{data.upTime}</Title>
              </div>
            </div>
            <div className="row">
              <div>
                <SubTitle className="subtitle">Son Gelen Mesaj</SubTitle>
                <SubTitle className="subtitle">
                  {" "}
                  <NewlineText text={data.sonGelen} />
                </SubTitle>
              </div>
              <div>
                <SubTitle className="subtitle">Son Gönderilen Mesaj</SubTitle>
                <SubTitle className="subtitle">
                  <NewlineText text={data.sonGiden} />
                </SubTitle>
              </div>
            </div>
          </div>
        ) : data.qr.length > 5 ? (
          <div className="row">
            <div>
              <QRCode size={256} value={data.qr} />
            </div>
          </div>
        ) : (
          "Qr Kodu Bekleniyor.."
        )
      ) : (
        "Yetkisiz Erişim / Data Yok"
      )}
    </div>
  );
};
export default Panel;
