import React, { useState } from "react";
import Select from "react-select";

import { Button, Input, SubTitle } from "reactbulma";

import "./LoginScreen.scss";
const options = [
  { value: "http://80.253.244.11:50056", label: "Bora Bot" },
  { value: "http://95.173.182.220:50055", label: "Hanımlar Bot" },
];

const LoginScreen = ({ handleLogin, connect }) => {
  const [sifre, setSifre] = useState("");
  return (
    <div style={{ backgroundColor: "#fff", padding: "20px" }}>
      <SubTitle>Giriş Yap</SubTitle>
      <Select
        placeholder="Bot Seç"
        options={options}
        onChange={({ value }) => {
          connect(value);
        }}
      />
      <br />
      <SubTitle>
        <Input
          value={sifre}
          large
          id="large"
          placeholder="Şifre"
          type="password"
          onChange={(e) => setSifre(e.target.value)}
        />
        <br />
        <br />

        <Button
          onClick={() => {
            handleLogin(sifre);
          }}
          large
          success
        >
          Giriş Yap
        </Button>
      </SubTitle>
    </div>
  );
};

export default LoginScreen;
